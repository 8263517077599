<template>
    <slot/>
</template>

<script setup lang="ts">
useHead({
    htmlAttrs: {
        // class: 'dark'
    }
})

</script>
